import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { Link } from '@/navigation';
import appConst from '~/const/appConst';

export const Hero = () => {
  const t = useTranslations('HERO');

  return (
    <section className="home-hero" data-testid="home-hero">
      <div className="hero">
        <div className="hero__slide">
          <div className="hero__media">
            <Image
              alt=""
              className="hero__picture"
              fill
              sizes="(max-width: 768px) 100vw, 60vw"
              priority
              src="https://storage.googleapis.com/cocreation-media-prod-4h-hbfe/hero/hero.webp"
              style={{ objectFit: 'cover' }}
            />
          </div>
          <div className="hero__text">
            <div className="hero__headings">
              <h2 className="hero__title vp-title-l">{t('TITLE')}</h2>
            </div>
            <div className="hero__cta">
              <Link
                data-testid="cta"
                className="button-wrapper-link vp-button vp-button--inverse"
                href={appConst.staticRoutes.about}
                prefetch={false}
              >
                <div className="vp-button__label" data-part="label">
                  {t('CTA')}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
