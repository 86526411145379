import Head from 'next/head';
import { useLocale, useTranslations } from 'next-intl';
import { env } from '~/const/env';
import { seo } from '~/const/seo';
import { getAbsoluteRouteFromPath } from '~/utils/utils';
import { defaultLocale, locales, usePathname } from '@/navigation';
import { LocaleCode } from '~/const/locale';

interface HeadPagePropsType {
  title?: string;
  description?: string;
  image?: string;
  noIndex?: boolean;
}

export const HeadPage = ({
  title,
  description,
  image = seo.APP_LOGO,
  noIndex = false,
}: HeadPagePropsType) => {
  const t = useTranslations();
  const pathname = usePathname();
  const locale = useLocale();

  const url = getAbsoluteRouteFromPath(
    env.REACT_APP_DK_CONNECT_REDIRECT_URI,
    pathname,
  );

  description = description || t('SEO.DESCRIPTION');
  title = title || t('SEO.TITLE');

  const xDefault = 'x-default';
  const pathnamePrefix = (entry: string) => entry === defaultLocale ? '' : `/${entry}`;
  const pathnameWithLang = (entry: string) => `${pathnamePrefix(entry)}${pathname === '/' ? '' : pathname}`;
  const HOST: string = env.REACT_APP_DK_CONNECT_REDIRECT_URI.slice(0, -1);

  const canonical = pathnameWithLang(locale);
  const languages = Object.fromEntries(
    [...locales, xDefault].map(entry => [
      entry,
      pathnameWithLang(entry === xDefault ? LocaleCode.fr_FR : entry),
    ]),
  );

  return (
    <Head>
      <title key="title">{title}</title>
      <meta name="description" content={description} key="description" />
      <link rel="canonical" href={`${HOST}${canonical}`} />
      {Object.entries(languages).map(([key, value]) => (
        <link
          key={`hreflang-${key}`}
          rel="alternate"
          hrefLang={key}
          href={`${HOST}${value}`}
        />
      ))}
      {/* Schema.org meta for Google+ */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />

      {/* Twitter Card meta */}
      <meta name="twitter:card" content={image} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:url" content={url} />
      <meta
        name="twitter:domain"
        content={env.REACT_APP_DK_CONNECT_REDIRECT_URI}
      />

      {/* Open Graph meta */}
      <meta property="og:title" content={title} key="og:title" />
      <meta property="og:url" content={url} key="og:url" />
      <meta property="og:image" content={image} />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      <meta property="og:site_name" content={t('SEO.NAME')} />
      <meta property="og:locale" content="fr" />
      <meta property="og:type" content="website" />

      <meta property="fb:app_id" content="566793097117580" />
      <meta
        name="google-site-verification"
        content={process.env.GOOGLE_SITE_VERIFICATION}
      />
      {noIndex && (
        <>
          <meta key="robots" name="robots" content="noindex,nofollow" />
          <meta key="googlebot" name="googlebot" content="noindex,nofollow" />
        </>
      )}
    </Head>
  );
};
