export const FollowedProjectsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="224" height="126" fill="none" viewBox="0 0 224 126">
      <path fill="#fff" d="M0 .63h224v125.091H0z" />
      <path fill="#F5F4F5" d="M14.578 20.067h113.708v90.383H14.578z" />
      <path stroke="#E1E0DF" strokeWidth="4.859" d="M137.032 32.215h63.171m-63.171 10.691h37.903" />
      <path fill="#ABB2EB" d="m112.93 78.568 1.305 4.508-21.03 11.405-.918-7.382 20.643-8.53Z" />
      <path fill="#24286B" d="m113.696 76.022 2.068 4.757-22.65 11.939-1.478-7.713 22.06-8.983Z" />
      <path fill="#F1F0EE" d="M73.614 37.776s19.204-2.537 35.65 26.626l-20.78 7.713-14.87-34.34Z" />
      <path fill="#24286B" fillRule="evenodd" d="m73.615 37.776-.027-.24-.307.04 15.085 34.838 21.236-7.882-.144-.255c-8.246-14.622-17.195-21.315-24.1-24.348-3.45-1.515-6.386-2.114-8.463-2.334a19.177 19.177 0 0 0-2.427-.113 11.503 11.503 0 0 0-.863.052l-.012.001h-.004c0 .001-.001.001.026.24Zm.862.189a11.72 11.72 0 0 0-.522.022l14.648 33.828 20.325-7.544c-8.159-14.362-16.971-20.922-23.74-23.895-3.406-1.496-6.299-2.085-8.337-2.3a18.762 18.762 0 0 0-2.374-.111Z" clipRule="evenodd" />
      <path fill="#fff" d="M39.54 62.182s-9.16 15.532-9.554 16.905c-.394 1.373 9.553 5.177 9.553 5.177l52.59 1.69-.494-8.348-4.431-9.298-24.915-26.729s-22.061 15.216-22.75 20.603Z" />
      <path fill="#24286B" fillRule="evenodd" d="m62.32 41.271 25.068 26.893 4.47 9.377.51 8.662-52.87-1.699-.034-.013.076-.227-.076.227-.007-.002-.022-.009-.083-.032a73.92 73.92 0 0 1-1.44-.574 86.006 86.006 0 0 1-3.385-1.462c-1.221-.562-2.438-1.17-3.327-1.728-.444-.278-.817-.55-1.07-.804a1.571 1.571 0 0 1-.3-.395.647.647 0 0 1-.059-.469c.031-.107.098-.265.187-.453.09-.194.21-.434.355-.714.29-.559.681-1.28 1.139-2.106a410.944 410.944 0 0 1 3.267-5.75 1038.811 1038.811 0 0 1 4.528-7.765l.077-.132c.11-.728.555-1.578 1.213-2.493.68-.946 1.607-1.992 2.694-3.089 2.174-2.193 5.004-4.607 7.807-6.847a214.624 214.624 0 0 1 7.667-5.826 224.071 224.071 0 0 1 3.399-2.42l.063-.045.122.203-.122-.203.153-.105Zm-.062.617-.095.066-.7.491a222.16 222.16 0 0 0-2.506 1.796 213.981 213.981 0 0 0-7.65 5.813c-2.798 2.237-5.613 4.637-7.768 6.812-1.078 1.088-1.987 2.114-2.646 3.031-.664.925-1.053 1.71-1.13 2.318l-.007.052-.026.044-.19-.13.19.13-.103.175-.291.495a1177.043 1177.043 0 0 0-4.234 7.266 410.9 410.9 0 0 0-3.264 5.743c-.456.824-.845 1.54-1.132 2.094-.143.277-.26.51-.347.696-.089.19-.14.314-.158.378 0 .004-.008.031.026.1.035.07.102.16.21.27.216.216.555.467.99.739.864.542 2.06 1.141 3.277 1.702a85.585 85.585 0 0 0 3.367 1.454 96.778 96.778 0 0 0 1.434.57l.077.03 52.306 1.681-.474-8.033-4.394-9.22-24.762-26.563Z" clipRule="evenodd" />
      <path fill="#ABB2EB" d="m30.266 81.029 62.142 6.868.986 6.762S69.046 92.84 60.33 91.692c-8.716-1.149-32.723-4.852-32.723-4.852l2.66-5.811Z" />
      <path fill="#3643BA" d="m29.96 79.06 62.142 6.868.985 6.763s-24.348-1.82-33.064-2.968c-8.715-1.148-32.722-4.851-32.722-4.851l2.659-5.812Z" />
      <path fill="#24286B" fillRule="evenodd" d="m29.826 78.803 62.47 6.904 1.056 7.245-.281-.02.015-.242-.015.241-.276-.02a1022.293 1022.293 0 0 1-3.665-.282c-2.42-.188-5.736-.452-9.324-.75-7.176-.599-15.447-1.341-19.81-1.916-4.36-.575-12.544-1.788-19.634-2.857a4475.61 4475.61 0 0 1-12.045-1.834l-1.049-.162.032-.239-.032.24-.306-.048 2.864-6.26Zm-2.188 5.877.743.114a4463.536 4463.536 0 0 0 12.043 1.834c7.092 1.07 15.27 2.282 19.626 2.856 4.352.573 12.613 1.314 19.79 1.913a1752.498 1752.498 0 0 0 12.98 1.031l-.914-6.279-61.814-6.832-2.454 5.363Z" clipRule="evenodd" />
      <path fill="#3643BA" d="M88.78 71.587s2.758 9.508 3.35 14.369l21.568-9.931s-5.318-9.931-6.499-11.2L88.78 71.587Z" />
      <path fill="#24286B" fillRule="evenodd" d="m107.26 64.547.099.106c.163.175.385.485.643.877.261.397.568.893.901 1.449a156.111 156.111 0 0 1 2.182 3.797 368.81 368.81 0 0 1 2.578 4.698l.171.318.06.111-.195.121.195-.12.126.235-22.073 10.164-.039-.316c-.293-2.412-1.127-5.99-1.89-8.975a203.427 203.427 0 0 0-1.42-5.247l-.023-.08-.008-.027.215-.072-.215.072-.065-.224 18.758-6.887Zm-18.2 7.192c.017.055.035.12.056.195a203.314 203.314 0 0 1 1.335 4.95c.731 2.859 1.534 6.288 1.862 8.725l21.065-9.7-.105-.193a351.279 351.279 0 0 0-2.573-4.692 160.224 160.224 0 0 0-2.175-3.784 43.998 43.998 0 0 0-.89-1.431 9.191 9.191 0 0 0-.501-.706L89.061 71.74Z" clipRule="evenodd" />
      <path fill="#ABB2EB" d="M60.516 44.644 34.812 66.092s14.673-29.373 38.801-28.316l-13.097 6.868Z" />
      <path fill="#24286B" fillRule="evenodd" d="m35.574 65.15 24.823-20.712L72.69 37.99c-11.55-.14-20.872 6.71-27.366 13.716-3.33 3.593-5.91 7.22-7.657 9.948a65.629 65.629 0 0 0-2.093 3.494Zm-.762.941-.198-.114-.502 1.004L60.636 44.85l13.875-7.277-.889-.039c-12.155-.532-21.913 6.6-28.617 13.833-3.354 3.618-5.95 7.269-7.709 10.015a66.088 66.088 0 0 0-2 3.325 43.053 43.053 0 0 0-.638 1.184l-.033.064-.008.016-.003.005.198.115Z" clipRule="evenodd" />
      <path fill="#ABB2EB" d="m60.516 44.644 27.968 27.471s-3.35-25.57-14.871-34.337c.001-.002-5.514-.74-13.097 6.866Z" />
      <path fill="#24286B" fillRule="evenodd" d="M73.72 37.616a.258.258 0 0 0-.058-.074.501.501 0 0 0-.012-.002l-.007-.002h-.007a.509.509 0 0 0-.069-.008 3.785 3.785 0 0 0-.199-.011 6.83 6.83 0 0 0-.75.02c-.652.05-1.595.2-2.775.588-2.362.777-5.666 2.514-9.48 6.34l-.178.179 28.609 28.1-.087-.665-.223.034.223-.034-.001-.005-.002-.014-.008-.056-.03-.217A89.73 89.73 0 0 0 88 67.934a99.577 99.577 0 0 0-2.418-9.775c-2.28-7.494-6.016-16.146-11.84-20.578l-.023.035Zm14.443 33.857-.067-.433a99.055 99.055 0 0 0-2.94-12.73c-2.271-7.463-5.956-15.953-11.626-20.3a3.39 3.39 0 0 0-.177-.01 6.424 6.424 0 0 0-.704.02c-.62.048-1.53.19-2.676.567-2.255.743-5.434 2.399-9.126 6.055l27.315 26.83ZM73.581 38.017Z" clipRule="evenodd" />
      <path fill="#ABB2EB" d="M61.074 57.499s-4.07-1.62-7.615 2.605c-3.545 4.226-5.45 13.384-4.662 21.132l1.642.281L61.074 57.5Z" />
      <path fill="#24286B" fillRule="evenodd" d="m61.153 57.272-.078.227.078-.227.23.092-10.813 24.42-1.977-.34-.019-.182c-.397-3.903-.115-8.157.71-11.967.826-3.806 2.2-7.197 4.008-9.352 1.816-2.164 3.775-2.84 5.287-2.967a6.708 6.708 0 0 1 1.845.098 5.421 5.421 0 0 1 .713.192l.01.005h.004l.002.001Zm-.394.376a5.127 5.127 0 0 0-.416-.099 6.286 6.286 0 0 0-1.729-.091c-1.41.119-3.258.746-4.988 2.808-1.737 2.07-3.087 5.372-3.903 9.138-.803 3.702-1.084 7.828-.72 11.622l1.305.224L60.76 57.648Z" clipRule="evenodd" />
      <path fill="#24286B" d="M50.438 81.517s.132-10.706 1.51-15.073c1.38-4.366 3.94-9.227 8.076-9.086 4.137.14 7.55 2.959 8.93 9.158 1.379 6.198.919 17.609.919 17.609l-19.435-2.608Z" />
      <path fill="#24286B" fillRule="evenodd" d="M55.125 60.364c-1.305 1.712-2.28 3.988-2.965 6.158-.678 2.15-1.055 5.887-1.26 9.113a131.885 131.885 0 0 0-.234 5.67l18.99 2.548a113.633 113.633 0 0 0 .041-1.612c.022-1.165.036-2.782.007-4.592-.057-3.626-.287-8.005-.97-11.077-.682-3.065-1.861-5.271-3.363-6.728-1.5-1.455-3.334-2.176-5.355-2.245-1.973-.067-3.587 1.055-4.89 2.765Zm14.746 23.761.225.01.001-.033a7.846 7.846 0 0 0 .004-.097 85.635 85.635 0 0 0 .046-1.755c.021-1.168.036-2.792.007-4.609-.057-3.629-.287-8.054-.983-11.181-.697-3.135-1.914-5.437-3.498-6.974-1.586-1.539-3.526-2.297-5.643-2.37-2.163-.073-3.898 1.165-5.253 2.942-1.353 1.775-2.349 4.113-3.042 6.309-.7 2.217-1.079 6.016-1.284 9.235a132.43 132.43 0 0 0-.237 5.79l-.001.091v.03l.224.004-.224-.003-.003.216 19.876 2.667.01-.262-.225-.01ZM34.82 65.43l.19.133.187.132-.021.035-.063.104c-.055.091-.137.226-.241.401a187.899 187.899 0 0 0-3.73 6.554c-2.192 4.04-4.694 9.042-5.967 12.928l-.424-.16c1.288-3.933 3.81-8.969 6.002-13.01a188.369 188.369 0 0 1 3.983-6.976l.063-.105.022-.035Zm53.256 5.495.213-.08.212-.079.003.01.01.031a32.408 32.408 0 0 1 .184.58 164.237 164.237 0 0 1 2.147 7.57c1.207 4.691 2.492 10.601 2.79 15.434l-.449.032c-.294-4.782-1.57-10.653-2.775-15.338a163.687 163.687 0 0 0-2.14-7.544l-.144-.458-.038-.118-.01-.03-.003-.01Zm21.011-6.374.177-.149.177-.149.006.008.017.024.066.092.254.356a95.306 95.306 0 0 1 3.768 5.786c2.129 3.552 4.442 7.997 5.092 11.48l-.441.095c-.632-3.384-2.899-7.76-5.029-11.314a95.026 95.026 0 0 0-3.748-5.755l-.251-.353-.066-.09-.017-.023-.005-.008Z" clipRule="evenodd" />
      <path fill="#24286B" d="M24.915 85.955c.37 0 .672-.22.672-.493s-.301-.494-.672-.494c-.372 0-.673.22-.673.493s.301.494.673.494Zm68.296 8.735c.372 0 .673-.22.673-.493s-.301-.494-.673-.494c-.37 0-.672.221-.672.494s.301.494.672.494Zm25.162-12.256c.371 0 .672-.221.672-.494s-.301-.494-.672-.494c-.372 0-.673.221-.673.494s.301.494.673.494Z" />
      <path fill="#fff" fillRule="evenodd" d="M73.275 40.571a.219.219 0 0 1 .298-.117c.889.445 2.377 1.777 4.051 4.31 1.679 2.54 3.555 6.303 5.21 11.64a.246.246 0 0 1-.143.305c-.118.042-.245-.026-.284-.153-1.643-5.298-3.5-9.018-5.15-11.513-1.654-2.503-3.088-3.758-3.874-4.151a.25.25 0 0 1-.108-.321Z" clipRule="evenodd" />
      <path fill="#24286B" fillRule="evenodd" d="M92.729 47.568c.082-.1.224-.11.317-.022.423.399.824.773 1.204 1.13 1.882 1.759 3.291 3.076 4.684 4.777 1.678 2.048 3.331 4.648 5.78 9.247a.25.25 0 0 1-.084.329.216.216 0 0 1-.306-.09c-2.444-4.588-4.079-7.155-5.727-9.167-1.372-1.675-2.755-2.968-4.636-4.727-.382-.358-.785-.734-1.212-1.137a.254.254 0 0 1-.02-.34Z" clipRule="evenodd" />
      <path fill="#fff" fillRule="evenodd" d="M106.697 67.206a.217.217 0 0 1 .309.081l3.066 5.428.006.016c.04.1.085.206.131.314.077.182.157.371.222.55.107.297.191.614.189.943-.004.692-.388 1.331-1.403 1.944-1.99 1.2-5.16 2.049-6.49 2.323-.122.025-.24-.06-.263-.192-.023-.13.056-.257.178-.282 1.321-.273 4.431-1.11 6.355-2.27.943-.57 1.17-1.078 1.173-1.526.002-.241-.06-.492-.159-.765a9.596 9.596 0 0 0-.202-.503 23.036 23.036 0 0 1-.138-.33l-3.05-5.4a.252.252 0 0 1 .076-.33Z" clipRule="evenodd" />
    </svg>

  );
};
