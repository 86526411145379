export const TopProjectSkeleton = () => (
  <div className="skeleton-template top-projects-skeleton">
    <div className="project-item project-item--horizontal">
      <span className="project-item__link">
        <header className="project-item__header">
          <span className="project-item__media">
            <span className="skeleton__media vtmn-skeleton" />
          </span>
        </header>
      </span>
      <footer className="project-item__body">
        <div className="project-item__title vp-title-m">
          <div className="skeleton__item vtmn-skeleton" />
        </div>
        <p className="vp-body-m">
          <span className="skeleton__item vtmn-skeleton" />
        </p>
      </footer>
    </div>
  </div>
);

const TopProjectsSkeletonItem = () => (
  <div className="project-item project-item--horizontal">
    <span className="project-item__link">
      <header className="project-item__header">
        <span className="project-item__media">
          <span className="skeleton__media vtmn-skeleton" />
        </span>
      </header>
    </span>
    <footer className="project-item__body">
      <span className="skeleton__item vtmn-skeleton" />
    </footer>
  </div>
);

const TopProjectsSkeletonSlide = () => (
  <div className="react-horizontal-scrolling-menu--item top-projects-scroller__item">
    <TopProjectsSkeletonItem />
    <TopProjectsSkeletonItem />
    <TopProjectsSkeletonItem />
  </div>
);

export const TopProjectsSkeleton = () => (
  <div className="skeleton-template top-projects-skeleton">
    <div className="react-horizontal-scrolling-menu--wrapper top-projects-scroller">
      <span className="projects-scroller__arrows projects-scroller__arrows--left">
        <span className="skeleton__arrow vtmn-skeleton" />
      </span>
      <div className="react-horizontal-scrolling-menu--scroll-container top-projects-scroller__container">
        <TopProjectsSkeletonSlide />
        <div className="react-horizontal-scrolling-menu--separator" />
        <TopProjectsSkeletonSlide />
        <div className="react-horizontal-scrolling-menu--separator" />
        <TopProjectsSkeletonSlide />
      </div>
      <span className="projects-scroller__arrows projects-scroller__arrows--right">
        <span className="skeleton__arrow vtmn-skeleton" />
      </span>
    </div>
  </div>
);
