import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ProjectItemContextEnum } from '../ProjectsScroller/model';
import { ProjectsScroller } from '../ProjectsScroller/ProjectsScroller';
import { FollowedProjectsSkeleton } from './skeletons/skeletons';
import { FollowedProjectsEmpty } from './FollowedProjectsEmpty';
import { actionsProjects } from '~/actions/actionsProjects';
import { MainColumn } from '~/components/commons/MainColumn/MainColumn';
import type { UseDispatchType } from '~/model/GlobalTypes';
import {
  authenticatedSelector,
  hpFollowedProjectsSelector,
  userLanguageSelector,
  userSelector,
} from '~/selectors/selectors';
import { isAuthenticatedAsMainUser } from '~/utils/user/user';

export const FollowedProjects = () => {
  const t = useTranslations();
  const [isLoading, setIsLoading] = useState(false);

  // REDUX
  const dispatch = useDispatch<UseDispatchType>();
  const authenticated = useSelector(authenticatedSelector);
  const user = useSelector(userSelector);
  const userLanguage = useSelector(userLanguageSelector);
  const hpFollowedProjects = useSelector(hpFollowedProjectsSelector);

  const authenticatedAsMainUser = isAuthenticatedAsMainUser(
    user,
    authenticated,
  );

  const displaySkeleton
    = !user
    || (!hpFollowedProjects && authenticatedAsMainUser && !!userLanguage)
    || isLoading;

  const renderFollowedProjects = hpFollowedProjects?.length
    ? (
      <>
        <h2 className="vp-title-m">{t('HOMEPAGE.FOLLOWED_PROJECTS')}</h2>
        <ProjectsScroller
          data={hpFollowedProjects?.slice(0, 5)}
          instanceName={ProjectItemContextEnum.followed}
        />
      </>
      )
    : (
      <FollowedProjectsEmpty />
      );

  useEffect(() => {
    if (!hpFollowedProjects && authenticatedAsMainUser && !!userLanguage) {
      setIsLoading(true);
      dispatch(
        actionsProjects.getHpFollowedProjectsAction(user.id, userLanguage),
      ).finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedAsMainUser, !!userLanguage]);

  return (
    <section
      className={classNames('followed-projects', {
        'followed-projects--empty':
          !displaySkeleton && !hpFollowedProjects?.length,
      })}
    >
      <MainColumn>
        {displaySkeleton
          ? (
            <FollowedProjectsSkeleton />
            )
          : (
              renderFollowedProjects
            )}
      </MainColumn>
    </section>
  );
};
