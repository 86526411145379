import type { AxiosError } from 'axios';
import { actionsHistoryUrl } from '~/actions/actionsHistoryUrl';
import { actionsMaintenance } from '~/actions/actionsMaintenance';
import { apiProjects } from '~/api/apiProjects';
import { getDataCollection, getResponseData } from '~/api/common/callApi';
import { Homepage } from '~/components/Homepage/Homepage';
import appConst from '~/const/appConst';
import { checkIsClientSideRouting, getLocaleFromLanguage } from '~/utils/utils';
import { wrapper } from '@/store';
import { actionsProjects } from '~/actions/actionsProjects';
import { api } from '~/const/api';
import { defaultLocale } from '@/navigation';
import { type LocaleCode, localeSubpaths } from '~/const/locale';

const HomepagePage = () => <Homepage />;

export const getServerSideProps = wrapper.getServerSideProps(
  store =>
    async ({ req, query: { locale: language = defaultLocale } }) => {
      if (!Object.values(localeSubpaths).includes(language as LocaleCode)) {
        return {
          notFound: true,
        };
      }

      const locale = getLocaleFromLanguage(language as LocaleCode, true);
      const initialProps = {
        props: {
          messages: (
            await import(`@/public/static/locales/${language}/common.json`)
          ).default,
        },
      };

      // Check if triggered by client-side routing
      const isCSR = checkIsClientSideRouting(req);
      if (isCSR) {
        console.info('Client Side Routing');
        return initialProps;
      }

      // ==== PROJECTS ====
      const errorCatcher = (error: AxiosError) => {
        actionsMaintenance.checkMaintenanceStatus(error, store.dispatch);
        return initialProps;
      };

      const topProjects = apiProjects.getProjects({
        locale,
        pageUrl: `${api.PROJECTS}?order_top_projects`,
      });

      const fetchedProjects = apiProjects.getProjects({
        sorting: true,
        locale,
        itemsPerPage: 8,
      });

      await Promise.all([topProjects, fetchedProjects])
        .then(([topProjectsResponse, fetchedProjectsResponse]) => {
          const topProjectsData = getResponseData(topProjectsResponse);
          const fetchedProjectsData = getResponseData(fetchedProjectsResponse);

          if (topProjectsData) {
            store.dispatch(
              actionsProjects.getTopProjects(
                getDataCollection(topProjectsData),
              ),
            );
          }

          if (fetchedProjectsData) {
            store.dispatch(
              actionsProjects.getHpProjects(
                getDataCollection(fetchedProjectsData),
              ),
            );
          }
        })
        .catch(errorCatcher);

      // ==== HISTORY ====
      const actionHistoryUrl = actionsHistoryUrl.addHistoryUrl(
        appConst.staticRoutes.index,
        appConst.staticRoutes.index,
      );
      store.dispatch(actionHistoryUrl);

      return initialProps;
    },
);

export default HomepagePage;
