import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectItemContextEnum } from '../ProjectsScroller/model';
import { ProjectsScroller } from '../ProjectsScroller/ProjectsScroller';
import { chunkArray } from '../ProjectsScroller/utils';
import { TopProjectSkeleton, TopProjectsSkeleton } from './skeletons/skeletons';
import { actionsProjects } from '~/actions/actionsProjects';
import { MainColumn } from '~/components/commons/MainColumn/MainColumn';
import { ProjectsListItem } from '~/components/ProjectsListItem/ProjectsListItem';
import type { UseDispatchType } from '~/model/GlobalTypes';
import type { ProjectType } from '~/model/ProjectType';
import {
  hpTopProjectsSelector,
  userLanguageSelector,
} from '~/selectors/selectors';

export const TopProjects = () => {
  const t = useTranslations();

  const [isLoading, setIsLoading] = useState(false);

  // REDUX
  const dispatch = useDispatch<UseDispatchType>();
  const userLanguage = useSelector(userLanguageSelector);
  const topProjects = useSelector(hpTopProjectsSelector);

  useEffect(() => {
    // Get topProjects when user is defined (as logged or not)
    if (!topProjects && !!userLanguage) {
      setIsLoading(true);
      dispatch(actionsProjects.getTopProjectsAction(userLanguage)).finally(() =>
        setIsLoading(false),
      );
    } else if (!topProjects) {
      setIsLoading(true);
    }
  }, [!!userLanguage]);

  // keep 9 recommandations after the first one
  const topProjectsOthers: ProjectType[] = topProjects?.slice(1, 10);

  // Divide topProjects in groups of 3
  const topProjectsGroups: ProjectType[][] = chunkArray(topProjectsOthers, 3);

  const notopProjects = topProjects?.length === 0;

  if (notopProjects && !isLoading)
    return null;

  return (
    <section className="top-projects">
      <MainColumn>
        <h2 className="vp-title-m">{t('HOMEPAGE.TOP_PROJECTS.MULTIPLE')}</h2>
        <p className="homepage__secondary-text vp-body-m">
          {t('HOMEPAGE.TOP_PROJECTS.SUB_TITLE')}
        </p>
        <div
          className={classNames('top-projects__inner', {
            'top-projects__inner--flex':
              isLoading || topProjectsGroups?.length > 0,
          })}
        >
          <div className="top-projects__staring">
            {!isLoading && topProjects?.[0]
              ? (
                <ProjectsListItem
                  project={topProjects[0]}
                  instanceName={ProjectItemContextEnum.staring}
                  imageSizes="(max-width: 768px) 80vw, 50vw"
                />
                )
              : (
                <TopProjectSkeleton />
                )}
          </div>
          <div className="top-projects__scroller">
            {!isLoading
              ? (
                  topProjectsGroups?.length > 0 && (
                    <ProjectsScroller
                      data={topProjectsGroups}
                      instanceName={ProjectItemContextEnum.top_projects}
                    />
                  )
                )
              : (
                <TopProjectsSkeleton />
                )}
          </div>
        </div>
      </MainColumn>
    </section>
  );
};
