import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import { VpIcon } from '@vtmn-play/react/headless';
import { Link } from '@/navigation';
import { OptionsStatusValueType } from '~/components/Projects/model';

export const FollowedProjectsScrollerTail = () => {
  const t = useTranslations();

  return (
    <article className={classNames('project-item', 'project-item--tail')}>
      <span className="project-item__access">
        <Link
          className="project-item__access-link"
          href={`/projects?status=${OptionsStatusValueType.followed}`}
          prefetch={false}
        >
          {t('HOMEPAGE.FOLLOWED.ALL_MY_FOLLOWED_PROJECTS')}
          <span className="project-item__goto">
            <VpIcon name="arrow-right" size={20} />
          </span>
        </Link>
      </span>
      <span className="project-item__access">
        <Link
          className="project-item__access-link"
          href={`/projects?status=${OptionsStatusValueType.user}`}
          prefetch={false}
        >
          {t('HOMEPAGE.FOLLOWED.ALL_MY_PARTICIPATIONS')}
          <span className="project-item__goto">
            <VpIcon name="arrow-right" size={20} />
          </span>
        </Link>
      </span>
    </article>
  );
};
