import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { Hero } from '../Hero/Hero';
import {
  applyMemorizedWindowScroll,
  memorizeScroll,
  sessionStorageScrollerSuffix,
  useBrowserLayoutEffect,
} from '../utils';
import { getActiveProjectsIds } from '../Projects/utils';
import { CompleteProfileBannerHomepage } from '../commons/CompleteProfileBanner/CompleteProfileBannerHomepage';
import { TopProjects } from './TopProjects/TopProjects';
import { FollowedProjects } from './FollowedProjects/FollowedProjects';
import { AllProjects } from './AllProjects/AllProjects';
import {
  getLocalStorage,
  getSessionStorage,
  isDecathlonian,
  isUserDefined,
  redirectToLogin,
  removeLocalStorage,
} from '~/utils/utils';
import { HeadPage } from '~/components/commons/HeadPage/HeadPage';
import type { UseDispatchType } from '~/model/GlobalTypes';
import { ScrollMemorisation } from '~/model/GlobalTypes';
import appConst from '~/const/appConst';
import type { ProjectType } from '~/model/ProjectType';
import { actionsProjects } from '~/actions/actionsProjects';
import {
  authenticatedSelector,
  hpFollowedProjectsSelector,
  hpProjectsDataSelector,
  projectTagsSelector,
  userSelector,
} from '~/selectors/selectors';
import { isAuthenticatedAsMainUser } from '~/utils/user/user';

const DynamicProjectCreation = dynamic(
  () =>
    import('./ProjectCreation/ProjectCreation').then(
      res => res.ProjectCreation,
    ),
  {
    ssr: false,
  },
);

export const Homepage = () => {
  const dispatch = useDispatch<UseDispatchType>();
  const authenticated = useSelector(authenticatedSelector);
  const user = useSelector(userSelector);
  const tags = useSelector(projectTagsSelector);
  const hpFollowedProjects = useSelector(hpFollowedProjectsSelector);
  const projects = useSelector(hpProjectsDataSelector);

  const userIsDefined = isUserDefined(user);
  const userAuthenticated = isAuthenticatedAsMainUser(user, authenticated);

  const getProjectTags = (collection: ProjectType[]) => {
    // Store list of projects ids (2,6,22)
    // if at least one project is active
    // and the matching tag has not already been fetched yet
    const projectsIds = Array.from(
      new Set(getActiveProjectsIds(collection, tags)),
    );
    // Silently dispatch projects tags fetch if user is connected
    if (projectsIds.length > 0)
      dispatch(actionsProjects.getProjectTagsAction(projectsIds));
  };

  const scrollSession = getSessionStorage(
    `${ScrollMemorisation.home}${sessionStorageScrollerSuffix}`,
  );

  const redirectLocalStorageKey = appConst.localStorageKeys.redirectAfterLogout;
  const redirectTo = getLocalStorage(redirectLocalStorageKey);

  useEffect(() => {
    if (
      // case when the user is connected we have to wait the call of
      // the hpFollowedProjects and projects routes
      (!tags && hpFollowedProjects && projects && userAuthenticated)
      // case if user is defined but not connected we have to wait
      // the call of the project route
      || (!tags && projects && userIsDefined && !userAuthenticated)
    ) {
      getProjectTags([...(hpFollowedProjects ?? []), ...projects]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hpFollowedProjects, projects, userIsDefined, userAuthenticated]);

  useEffect(() => {
    if (redirectTo) {
      removeLocalStorage(redirectLocalStorageKey);
      redirectToLogin(JSON.parse(redirectTo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectTo]);

  useEffect(() => {
    // Apply memorized Y Scroll if exists
    if (+scrollSession && window?.scrollY === 0)
      applyMemorizedWindowScroll(ScrollMemorisation.home);
  }, []);

  // Store Y Scroll value on unmount
  useBrowserLayoutEffect(() => {
    const onPagehideListener = () => {
      memorizeScroll(ScrollMemorisation.home);
    };

    window.addEventListener('pagehide', onPagehideListener);

    return () => {
      memorizeScroll(ScrollMemorisation.home);
      window.removeEventListener('pagehide', onPagehideListener);
    };
  }, []);

  return (
    <div className="homepage">
      <HeadPage />
      <Hero />
      <FollowedProjects />
      <TopProjects />
      <CompleteProfileBannerHomepage />
      <AllProjects />
      {isDecathlonian(user) && <DynamicProjectCreation />}
    </div>
  );
};
