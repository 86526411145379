import type { MessageKeys } from 'next-intl';
import { env } from './env';
import type { Messages } from '@/global';

export const seo = {
  APP_LOGO: `${env.REACT_APP_DK_CONNECT_REDIRECT_URI}icon-192.png`,
};

export const getMetaTitleSuffix = (t: any, title = t('SEO.NAME')) => {
  return `| ${title}`;
};

export interface BuildLanguagesUrlsProps {
  path?: string;
  lastmod?: string;
  changefreq?:
    | 'always'
    | 'hourly'
    | 'daily'
    | 'weekly'
    | 'monthly'
    | 'yearly'
    | 'never';
  priority?: number;
  locale?: string;
  title?: MessageKeys<
    Messages,
    | 'NAVIGATION.ABOUT'
    | 'NAVIGATION.GTS'
    | 'NAVIGATION.STS'
    | 'NAVIGATION.LEGAL_NOTICE'
    | 'NAVIGATION.DATA_USE'
  >;
}

export const staticPages: BuildLanguagesUrlsProps[] = [
  {
    path: '',
    priority: 1,
  },
  {
    path: 'about',
    lastmod: '2019-11-18',
    priority: 1,
    title: 'NAVIGATION.ABOUT',
  },
  {
    path: 'general-terms-of-service',
    lastmod: '2019-11-18',
    priority: 0.5,
    changefreq: 'yearly',
    title: 'NAVIGATION.GTS',
  },
  {
    path: 'special-terms-of-service',
    lastmod: '2019-11-18',
    priority: 0.5,
    changefreq: 'yearly',
    title: 'NAVIGATION.STS',
  },
  {
    path: 'legal-informations',
    lastmod: '2019-11-18',
    priority: 0.5,
    changefreq: 'yearly',
    title: 'NAVIGATION.LEGAL_NOTICE',
  },
  {
    path: 'privacy',
    lastmod: '2019-11-18',
    priority: 0.5,
    title: 'NAVIGATION.DATA_USE',
  },
];
