import { useEffect, useMemo } from 'react';
import { VpButton, VpIcon, VpIconButton } from '@vtmn-play/react/headless';
import { useSelector } from 'react-redux';
import { useTranslations } from 'next-intl';
import { CompleteProfileBannerWrapper } from './CompleteProfileBannerWrapper';
import { Link } from '@/navigation';
import appConst from '~/const/appConst';
import { authenticatedSelector, userSelector } from '~/selectors/selectors';
import { isAuthenticatedAsMainUser, isMainUser } from '~/utils/user/user';
import { isUserDefined, redirectToLogin } from '~/utils/utils';
import { useUserCriteria } from '~/hooks/criteria/form/useUserCriteria';
import { useCriteria } from '~/hooks/criteria/form/useCriteria';
import { useCriteriaCompletion } from '~/hooks/criteria/useCriteriaCompletion';
import { Loader } from '~/components/commons/Loader/Loader';

export const CompleteProfileBannerHomepage = () => {
  const t = useTranslations();

  const authenticated = useSelector(authenticatedSelector);
  const user = useSelector(userSelector);

  const { main_criterias, sports_profile } = appConst.staticRoutes;

  const { uriList: mainUserCriteriaUriList } = useUserCriteria({
    userCriteriaFilter: ['criterion.main=true'],
  });

  const { uriList: mandatoryUserCriteriaUriList } = useUserCriteria({
    userCriteriaFilter: ['criterion.mandatory=true'],
  });

  const { uriList: mainCriteriaUriList, getCriteria: getMainCriteria }
    = useCriteria({});

  const {
    uriList: mandatoryCriteriaUriList,
    getCriteria: getMandatoryCriteria,
  } = useCriteria({});

  const { missing: missingMain } = useCriteriaCompletion(
    mainCriteriaUriList,
    mainUserCriteriaUriList,
  );

  const { missing: missingMandatory } = useCriteriaCompletion(
    mandatoryCriteriaUriList,
    mandatoryUserCriteriaUriList,
  );

  const missing = useMemo(() => {
    return (
      !!missingMain
      && !!missingMandatory && [...missingMain, ...missingMandatory]
    );
  }, [missingMain, missingMandatory]);

  const count = useMemo(() => {
    return (
      +(!user?.sports?.length ? 1 : 0)
      + missing?.length
    );
  }, [missing, user?.sports]);

  useEffect(() => {
    if (isMainUser(user)) {
      Promise.all([
        getMainCriteria(['main=true']),
        getMandatoryCriteria(['mandatory=true']),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (
    !isUserDefined(user)
    || (isAuthenticatedAsMainUser(user, authenticated)
    && (!mainUserCriteriaUriList || !mandatoryUserCriteriaUriList))
  ) {
    return (
      <CompleteProfileBannerWrapper isHomepage>
        <VpIconButton data-testid="complete-profile-loading" disabled>
          <Loader loading inverted />
        </VpIconButton>
      </CompleteProfileBannerWrapper>
    );
  }

  return (
    <CompleteProfileBannerWrapper isHomepage>
      {isAuthenticatedAsMainUser(user, authenticated) && !!count && (
        <Link
          data-testid="complete-profile-hero-with-count"
          className="vp-button button-wrapper-link"
          href={main_criterias}
          prefetch={false}
        >
          <span className="vp-button__label" data-part="label">
            <div data-part="start-slot" data-testid="icon-timer">
              <VpIcon name="timer" />
            </div>
            {t('HOMEPAGE.COMPLETE_PROFILE.BUTTON', {
              count,
            })}
          </span>
        </Link>
      )}
      {isAuthenticatedAsMainUser(user, authenticated) && !count && (
        <Link
          data-testid="complete-profile-hero"
          className="vp-button button-wrapper-link"
          href={sports_profile}
          prefetch={false}
        >
          <span className="vp-button__label" data-part="label">
            <div data-part="start-slot" data-testid="icon-timer">
              <VpIcon name="sparkles" />
            </div>
            {t('HOMEPAGE.COMPLETE_PROFILE.BANNER.BUTTON')}
          </span>
        </Link>
      )}
      {!isAuthenticatedAsMainUser(user, authenticated) && (
        <VpButton
          data-testid="login-or-create-account"
          onClick={() => redirectToLogin(appConst.staticRoutes.index)}
        >
          {t('GLOBAL.LOGIN_OR_CREATE_ACCOUNT')}
        </VpButton>
      )}
    </CompleteProfileBannerWrapper>
  );
};
