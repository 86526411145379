import type { AxiosPromise } from 'axios';
import {
  getRequestBuilder,
  getUnpaginatedCollection,
  processCall,
} from './common/callApi';
import { CriteriaVisibilityStatus } from '~/const/appConst';
import { api } from '~/const/api';
import { env } from '~/const/env';
import type { CriteriaType } from '~/model/CriteriaType';
import type { DataCollectionType } from '~/model/DataCollectionType';
import type { CriteriaChoiceType } from '~/model/CriteriaChoiceType';
import type { LocaleType } from '~/model/GlobalTypes';

const getCriterion = async (
  id: number,
  locale: LocaleType,
): AxiosPromise<CriteriaType> => {
  const request = getRequestBuilder<CriteriaType>(
    `${env.REACT_APP_API}${api.CRITERIAS}/${id}`,
    false,
    locale,
  );
  return processCall(request);
};

const getCriterias = async (locale?: LocaleType, filters?: string[]) => {
  const queryFilters = filters ? `&${filters?.join('&')}` : '';
  return getUnpaginatedCollection<CriteriaType[]>({
    route: api.CRITERIAS,
    queryParams: `not_in[visibility]=${CriteriaVisibilityStatus.HIDDEN}${queryFilters}&not_in[type]=PARTICIPANT`,
    acceptLanguage: locale,
  });
};

const getMainCriterias = (
  locale?: LocaleType,
): AxiosPromise<DataCollectionType<CriteriaType>> => {
  const request = getRequestBuilder<DataCollectionType<CriteriaType>>(
    `${env.REACT_APP_API}${api.CRITERIAS}?main=true&order[weight]=asc`,
    false,
    locale,
  );
  return processCall(request);
};

const getCriterionChoices = async (criteriaId: number, locale?: LocaleType) => {
  return getUnpaginatedCollection<CriteriaChoiceType[]>({
    route: `${api.CRITERIAS}/${criteriaId}/${api.CHOICES}`,
    acceptLanguage: locale,
  });
};

export const apiCriterias = {
  getCriterion,
  getCriterionChoices,
  getCriterias,
  getMainCriterias,
};
