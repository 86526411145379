import type { ProjectScrollerContentType } from './model';
import type { ProjectType } from '~/model/ProjectType';

// Convert an Array into an Array of Arrays of a defined length
export const chunkArray = <T,>(arr: T[] = [], size = 3) =>
  // Calculate the length of the expected Array
  // and make the Array of this length
  Array.from({ length: Math.ceil(arr.length / size) }, (_, index) => {
    // Store the start position of the chunk to slice
    const startPosition = size * index;
    // and make a chunk from the start position to n(size) further
    return arr.slice(startPosition, startPosition + size);
  });

// Type Guard
export const checkIsArray = (
  toBeDetermined: ProjectScrollerContentType,
): toBeDetermined is Array<ProjectType> => {
  return Array.isArray(toBeDetermined);
};
