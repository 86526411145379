import { useCallback, useEffect, useState } from 'react';
import { useRequestState } from '../request/useRequestState';
import { getResponseData } from '~/api/common/callApi';
import { getUserData } from '~/api/apiUsers';

export enum UserDataProfileCompletion {
  CHILDREN = 'CHILDREN',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
  DIGITAL_USAGE = 'DIGITAL_USAGE',
  MEASUREMENT = 'MEASUREMENT',
  PROFILE = 'PROFILE',
  SPORTS = 'SPORTS',
}

export interface UserDataType {
  contribution_count?: number;
  profile_completion?: Record<UserDataProfileCompletion, number>;
}

export function useUserData(userId: number, filter: Array<keyof UserDataType>) {
  const { handleError, handleSuccess, startRequest } = useRequestState();
  const [userData, setUserData] = useState<UserDataType>();

  const handleFetchUserData = useCallback(async () => {
    if (!userId)
      return;

    try {
      startRequest();
      setUserData(getResponseData(await getUserData(userId, filter)));
      handleSuccess();
    } catch (error) {
      handleError();
    }
  }, [userId]);

  useEffect(() => {
    handleFetchUserData();
  }, [handleFetchUserData]);

  return { userData, update: handleFetchUserData };
}
