import { useContext } from 'react';
import classNames from 'classnames';
import type {
  publicApiType,
} from 'react-horizontal-scrolling-menu';
import {
  VisibilityContext,
} from 'react-horizontal-scrolling-menu';
import { useTranslations } from 'next-intl';
import type { VpIconProps } from '@vtmn-play/react/headless';
import { VpIcon, VpIconButton } from '@vtmn-play/react/headless';

const Arrow = ({
  disabled,
  noOverflow,
  onClick,
  side = 'left',
}: {
  disabled: boolean;
  noOverflow?: boolean;
  onClick: VoidFunction;
  side?: 'left' | 'right';
}) => {
  const t = useTranslations('FORM');
  const icon = `chevron-${side}` as VpIconProps['name'];

  return (
    <VpIconButton
      className={classNames(
        'projects-scroller__arrows',
        `projects-scroller__arrows--${side}`,
        { 'projects-scroller__arrows--disabled': disabled },
        { 'projects-scroller__arrows--hidden': noOverflow },
      )}
      aria-label={t('PREVIOUS_BUTTON')}
      size="small"
      variant="secondary"
      onClick={onClick}
      disabled={disabled}
    >
      <VpIcon name={icon} />
    </VpIconButton>
  );
};

export const LeftArrow = () => {
  const visibility = useContext<publicApiType>(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible('first', true);
  const isLastItemVisible = visibility.useIsVisible('last', true);

  return (
    <Arrow
      disabled={isFirstItemVisible}
      noOverflow={isFirstItemVisible && isLastItemVisible}
      onClick={() => visibility.scrollPrev('smooth')}
    />
  );
};

export const RightArrow = () => {
  const visibility = useContext<publicApiType>(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible('last', true);
  const isFirstItemVisible = visibility.useIsVisible('first', true);
  return (
    <Arrow
      disabled={isLastItemVisible}
      noOverflow={isFirstItemVisible && isLastItemVisible}
      onClick={() => visibility.scrollNext('smooth')}
      side="right"
    />
  );
};
