import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsErrors } from '~/actions/actionsErrors';
import { apiUsers } from '~/api/apiUsers';
import { getResponseData } from '~/api/common/callApi';
import type {
  CriteriaChoiceUserToDelete,
  UpdatingCriteriasState,
  UpdatingDataCriteria,
} from '~/components/Profile/model';
import {
  RequestStatus,
  useRequestState,
} from '~/hooks/request/useRequestState';
import type { CriteriaChoiceUserType } from '~/model/CriteriaChoiceType';
import type { UserType } from '~/model/UserType';
import { userLanguageSelector, userSelector } from '~/selectors/selectors';
import { filterUserCriteria } from '~/utils/user-criteria/filter';
import { initError } from '~/utils/utils';

export function useUserCriteria({
  child,
  userCriteriaFilter,
  onSubmit = ({ callback }) => Promise.resolve(callback()),
}: {
  child?: UserType;
  userCriteriaFilter?: string[];
  onSubmit?: ({ callback }: { callback: () => Promise<void> }) => Promise<void>;
}) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const userLanguage = useSelector(userLanguageSelector);

  const [userCriteria, setUserCriteria] = useState<CriteriaChoiceUserType[]>();

  const {
    status: getStatus,
    handleSuccess: getHandleSuccess,
    handleError: getHandleError,
    startRequest: getStartRequest,
  } = useRequestState();

  const {
    status: submitStatus,
    handleSuccess: submitHandleSuccess,
    handleError: submitHandleError,
    startRequest: submitStartRequest,
  } = useRequestState();

  const currentUser = child || user;

  const updateUserCriteria = (
    data: Array<CriteriaChoiceUserType & CriteriaChoiceUserToDelete>,
  ) => {
    setUserCriteria(state => filterUserCriteria(state || [], data));
  };

  const submit = async (updatingData: UpdatingCriteriasState) => {
    try {
      submitStartRequest();
      const data: UpdatingDataCriteria[] = Object.keys(updatingData).reduce(
        (acc, uri) => {
          acc.push({ ...updatingData[uri] });
          return acc;
        },
        [],
      );

      const response = getResponseData(
        await apiUsers.updateUserCriterias(data, userLanguage, child?.id),
      ) as Array<CriteriaChoiceUserType & CriteriaChoiceUserToDelete>;

      await onSubmit({
        callback: async () => updateUserCriteria(response),
      });

      submitHandleSuccess();
    } catch (error) {
      submitHandleError();
      dispatch(
        actionsErrors.throwErrorsAction(
          initError({
            ...error,
            response: {
              message: error.response?.message || 'ERROR_MESSAGE.GENERIC',
            },
          }),
        ),
      );
    }
  };

  const getUserCriteria = useCallback(
    async (filter = userCriteriaFilter) => {
      if (!!currentUser?.id && !!userLanguage && !!filter) {
        try {
          getStartRequest();
          const response = await apiUsers.getUserCriterias(
            currentUser,
            userLanguage,
            filter,
          );
          updateUserCriteria(
            response as Array<
              CriteriaChoiceUserType & CriteriaChoiceUserToDelete
            >,
          );
          getHandleSuccess();
        } catch (error) {
          getHandleError();
          dispatch(
            actionsErrors.throwErrorsAction(
              initError({
                ...error,
                response: {
                  message: error.response?.message || 'ERROR_MESSAGE.GENERIC',
                },
              }),
            ),
          );
        }
      }
    },
    [currentUser, userLanguage, userCriteriaFilter?.length],
  );

  const uriList = useMemo(() => {
    return userCriteria?.map(criterion => criterion.criterion['@id']);
  }, [userCriteria]);

  useEffect(() => {
    getUserCriteria();
  }, [getUserCriteria]);

  return {
    isFetching: getStatus === RequestStatus.PENDING,
    isUpdating: submitStatus === RequestStatus.PENDING,
    getUserCriteria,
    submit,
    uriList,
    userCriteria,
  };
}
