import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsErrors } from '~/actions/actionsErrors';
import { apiCriterias } from '~/api/apiCriterias';
import {
  RequestStatus,
  useRequestState,
} from '~/hooks/request/useRequestState';
import type { CriteriaType } from '~/model/CriteriaType';
import { userLanguageSelector } from '~/selectors/selectors';
import { uniqueKeepLast } from '~/utils/filter/filter';
import { initError } from '~/utils/utils';

export function useCriteria({ criteriaFilter }: { criteriaFilter?: string[] }) {
  const dispatch = useDispatch();
  const userLanguage = useSelector(userLanguageSelector);

  const [criteria, setCriteria] = useState<CriteriaType[]>();

  const { status, handleSuccess, handleError, startRequest }
    = useRequestState();

  const uriList = useMemo(() => {
    return criteria?.map(criterion => criterion['@id']);
  }, [criteria]);

  const getCriteria = useCallback(
    async (filter = criteriaFilter) => {
      if (!filter)
        return;
      try {
        startRequest();
        const response = await apiCriterias.getCriterias(userLanguage, filter);
        setCriteria(current =>
          uniqueKeepLast([...(current || []), ...response], '@id'),
        );
        handleSuccess();
      } catch (error) {
        handleError();
        dispatch(
          actionsErrors.throwErrorsAction(
            initError({
              ...error,
              response: {
                message: error.response?.message || 'ERROR_MESSAGE.GENERIC',
              },
            }),
          ),
        );
      }
    },

    [criteriaFilter?.length],
  );

  useEffect(() => {
    getCriteria();
  }, [getCriteria]);

  return {
    criteria,
    uriList,
    isFetching: status === RequestStatus.PENDING,
    getCriteria,
  };
}
