import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actionsProjects } from '~/actions/actionsProjects';
import type { UseDispatchType } from '~/model/GlobalTypes';
import {
  hpProjectsDataSelector,
  userLanguageSelector,
} from '~/selectors/selectors';
import { ProjectsList } from '~/components/ProjectsList/ProjectsList';

export const AllProjects = () => {
  const t = useTranslations();
  const dispatch = useDispatch<UseDispatchType>();
  const userLanguage = useSelector(userLanguageSelector);
  const projects = useSelector(hpProjectsDataSelector);
  const [isLoading, setIsLoading] = useState(false);

  const maxItems = 8;

  useEffect(() => {
    if (!projects && !!userLanguage) {
      setIsLoading(true);
      dispatch(actionsProjects.getHpProjectsAction(userLanguage)).finally(
        () => {
          setIsLoading(false);
        },
      );
    } else if (!projects) {
      setIsLoading(true);
    }
  }, [!!userLanguage]);

  return (
    <ProjectsList
      buttonLabel={t('INTERACTION.CROSS_SELL.BUTTON')}
      isLoading={isLoading || !projects}
      maxItems={maxItems}
      projects={projects}
      title={t('HOMEPAGE.SEE_MORE_PROJECTS')}
    />
  );
};
